














































































import { Observer } from "mobx-vue";
import { Component, Vue, Provide, Watch, Inject } from "vue-property-decorator";
import { TrainingGroundEventViewmodel } from "../viewmodels/training-ground-event-viewmodel";
import { StakeBoosters } from "@/constants/index";
import { FixedNumber } from "@ethersproject/bignumber";

@Observer
@Component({})
export default class APRDetail extends Vue {
  @Inject() vmEvent!: TrainingGroundEventViewmodel;
  boosters = StakeBoosters;

  estimatedAPR(multiplier) {
    return this.vmEvent.userApy.mulUnsafe(FixedNumber.from(multiplier + ""));
  }
}
